import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import HeaderMap from '../components/HeaderMap';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 25%;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('games');

    const icons = [
        {
            icon: 'icon-binoculars',
            text: t('DESCRIPTION_COMBINE_MAP_AR')
        },
        {
            icon: 'icon-map22',
            text: t('DESCRIPTION_TREASURE_HHUNT_PLACES')
        },
        {
            icon: 'icon-group',
            text: t('DESCRIPTION_ADVENTURES')
        },
        {
            icon: 'icon-mobile',
            text: t('DESCRIPTION_SDK_APP')
        }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_GAME')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_GAME')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_WEMAP_GAMES')}
                        size='large'
                        customersLogos={[
                            {image: '/images/products/games/louvre-lens.svg'},
                            {image: '/images/products/games/qh-aventures.svg'},
                            {image: '/images/products/games/logo_uh.png'},
                            {image: '/images/products/games/herault-logo.png'},
                            {image: '/images/products/games/rueil-malmaison.png'},
                            {image: '/images/products/games/crt-ile-de-france.png'}
                        ]}
                    />

                    <VideoIframe>
                        <IframeVideo title="youtube"
                            width="101%"
                            height="100%"
                            src="https://www.youtube.com/embed/389Id0RrUsM"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></IframeVideo>
                    </VideoIframe>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_BEST_UX')}
                        pictureRight={data.games.childImageSharp}
                        altDescription='mobiles with indoor augmented reality navigation and map'
                        titleButton={t('TITLE_BUTTON_GET_STARTED')}
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-location1',
                                text: t('ICON_DETECTION_GEOFENCING_QUIZZ')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('ICON_OUTDOOR_INDOOR')
                            },
                            {
                                icon: 'icon-mobile',
                                text: t('ICON_AVALAIBLE_MOBILE_WEB')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons
                            title={t('TITLE_GAMES')}
                            text={t('TITLE_COMPLETE_SERVICE')}
                            titleButton={t('TITLE_TALK_ABOUT_PROJECT')}
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <BgDarkBlue></BgDarkBlue>

                    <HeaderMap
                        customerCicleImageUrl='/images/industries/culture/logo_uhm.png'
                        alt='Journée Européennes du Patrimoine logo'
                        titleCaseStudy={t('TITLE_PUBLIC')}
                        title={t('TITLE_QHA')}
                        text={t('TITLE_BY_QHA')}
                        by={t('TEXT_BY')}
                        textPointInfos={t('TITLE_ESPACES')}
                        textViewsInfos={t('PUBLIC')}
                        descriptionCustomer={t('TITLE_DESCRIPTION_QHA')}
                        infosRight1={t('TITLE_WORK_INDOOR')}
                        infosRight2={t('TITLE_ANY_SCALE')} />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_LEVERAGE_POWER')}
                        pictureRightWidth="48%"
                        pictureRight={data.webApps.childImageSharp}
                        altDescription='mobile with connexion screen to media'
                        titleButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        urlFooter='/products/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-map2',
                                text: t('DESCRIPTION_CONNECT_CONTENT')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('DESCRIPTION_CREATE_DEPLOY_MAP')
                            },
                            {
                                icon: 'icon-user-plus',
                                text: t('DESCRIPTION_CONTENT_SERVICE')
                            },
                            {
                                icon: 'icon-coin-dollar',
                                text: t('DESCRIPTION_MONETIZE')
                            }
                        ]}
                    />

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with mobile augmented reality in museum'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  games: file(relativePath: {eq: "products/games/ouvres-lens.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 450, layout: FIXED)
    }
  }
  banner: file(relativePath: {eq: "products/games/banner-wemap-game.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  webApps: file(relativePath: {eq: "products/wemap-web/wemap_web_apps.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 420, layout: FIXED)
    }
  }
}
`;
