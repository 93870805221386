import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';
import LocalizedLink from './LocalizedLink';

import SecondaryButton from './SecondaryButton';

const TwoColumnsTitle = styled.h3`
    font-size: 24px;
    font-weight: 300;
`;

const Line = styled.hr`
    width:100%;
`;

const LayoutTwoColumns = styled.div`
    display:flex;
    flex-direction:column;
    width:80%;
    margin: 0 auto;
`;

const CellRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: flex-start;
    margin-top:2%;

    @media (max-width: 767px) {
        flex-direction:column;
    }
`;

const LeftCellText = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    margin-bottom:5%;
    margin-top:0%;
`;

const LeftCell = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-right:6%;

    @media (max-width: 767px) {
        width:100%;
    }
`;

const RightCell = styled.div`
    display: flex;
    flex-direction: column;
    width:40%;

    @media (max-width: 767px) {
        width:100%;
        margin-top: 10%;
    }
`;

const TitleRight = styled.p`
    margin:0%;
    font-weight: 300;
`;

const IconLeft = styled(GatsbyImage)`
    width:12%;
    margin-right:2%;

    @media (max-width: 767px) {
        width:50%;
        margin: 5% 0%;
    }
`;
const IconLeftImg = styled.img`
    width:12%;
    margin-right:2%;

    @media (max-width: 767px) {
        width:50%;
        margin: 5% 0%;
    }
`;

const IconMoon = styled.i`
    color: white;
    margin-right:6%;

    @media (max-width: 767px) {
        width:2vh;
        margin-right:10%;
    }
`;

const TextIcon = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    margin-top:0px;
    margin-bottom:0px;
`;

const LeftIcon = ({
    leftIcon, alt
}) => {
    return leftIcon.gatsbyImageData ? (
        <IconLeft image={getImage(leftIcon)}
            alt={alt} />
    ) : (
        <IconLeftImg src={leftIcon}
            alt={alt} />
    );
};

LeftIcon.propTypes = {
    leftIcon: PropTypes.object,
    alt: PropTypes.string
};

const RightCells = ({
    titleRight, icon, text
}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <TitleRight dangerouslySetInnerHTML={{ __html: titleRight }}></TitleRight>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '4%'
            }}>
                <IconMoon className={icon} />
                <TextIcon dangerouslySetInnerHTML={{ __html: text }}></TextIcon>
            </div>
        </div>
    );
};

RightCells.propTypes = {
    titleRight: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string
};

const TwoColumnsIcons = ({
    title, text, titleButton, urlFooter, icons, leftIcon, altColumnLogo = null
}) => {

    const rightCells = icons.map(i => {
        return (<RightCells key={i.text}
            titleRight={i.titleRight}
            icon={i.icon}
            text={i.text}/>);
    });

    return (
        <React.Fragment>
            <LayoutTwoColumns>
                <TwoColumnsTitle dangerouslySetInnerHTML={{ __html: title }}></TwoColumnsTitle>
                <Line />
                <CellRow>
                    {leftIcon ? <LeftIcon leftIcon={leftIcon}
                        alt={altColumnLogo} /> : null }
                    <LeftCell>
                        <LeftCellText dangerouslySetInnerHTML={{ __html: text }}></LeftCellText>
                        <LocalizedLink to={urlFooter}>
                            <SecondaryButton titleButton={titleButton}/>
                        </LocalizedLink>
                    </LeftCell>
                    <RightCell>
                        {rightCells}
                    </RightCell>
                </CellRow>
            </LayoutTwoColumns>
        </React.Fragment>
    );
};

TwoColumnsIcons.defaultProps = {icons: []};

TwoColumnsIcons.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    urlFooter: PropTypes.string,
    titleButton: PropTypes.string,
    leftIcon: PropTypes.string,
    altColumnLogo: PropTypes.string,
    icons: PropTypes.arrayOf(PropTypes.object)
};

export default TwoColumnsIcons;
